import axios from "axios";
import { request } from "@/utils/request";
// 查询数据
export function selectrecords(data) {
  return request({
    url: `/admin/articleRecords/select`,
    method: "POST",
    data,
  });
}

// 新增数据
export function addrecords(data) {
  return request({
    url: `/admin/articleRecords/add`,
    method: "POST",
    data,
  });
}

/* 修改数据 */
export function updaterecords(data) {
  return request({
    url: `/admin/articleRecords/update`,
    method: "POST",
    data,
  });
}

// 删除数据
export function deleterecords(data) {
  return request({
    url: `/admin/articleRecords/delete`,
    method: "POST",
    data,
  });
}

/* 设置推荐 */
export function updateRecommend(data) {
  return request({
    url: `/admin/articleRecords/updateRecommend`,
    method: "POST",
    data,
  });
}
