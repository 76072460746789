export default function() {
  /* 表格列头 */
  return [
    { label: "序号", type: "index", width: 70 },
    { label: "类型", prop: "type", slots: "type", width: 70 },
    { label: "创建人手机号", prop: "phone", width: 150 },
    // { label: "人物ID", prop: "accountID", width: 150 },
    { label: "标题", prop: "title", width: 220 },
    { label: "作者", prop: "name", width: 220 },
    { label: "笔记感想", prop: "message", width: 220 },
    // { label: "记录封面", prop: "img", slots: "img" },
    { label: "喜欢", prop: "recommend", slots: "recommend", width: 100 },
    { label: "类目", prop: "detail", width: 100 },
    { label: "年份", prop: "year", width: 100 },
    { label: "地区", prop: "address", width: 100 },

    // {
    //   label: "创建时间",
    //   prop: "createTime",
    //   width: 180,
    //   // slots: "state",
    // },
    {
      label: "操作",
      prop: "operation",
      width: 150,
      slots: "operation",
      // fixed: "right",
    },
  ];
}
